import { FormattedMessage } from "react-intl";
import VisibleTransition from "../visibleTransition";

interface ContactCardProps {
    className?: string;
    position: React.JSX.Element;
    email: string;
}

const ContactCard = ({
    className,
    email,
    position,
}: ContactCardProps) => <VisibleTransition className={`flex-[1_1_100%] min-[354px]:flex-[1_1_50%] py-[12px] md:py-[20.5px] xl:flex-auto flex flex-col items-center ${className}`}>
        {/*<p className="text-[10px] leading-[12.5px] md:text-[15px] md:leading-[18.5px] font-medium text-center text-gray100">*/}
        {/*    {position}*/}
        {/*</p>*/}
        <h5 className="text-[16px] md:text-[28px] leading-normal font-medium text-center text-white">
            {position}
        </h5>
        <p className="text-[10px] md:text-[15px] h-[12px] md:h-[36px] mt-[18px] md:mt-[45px] leading-normal font-medium text-center text-gray100">
            Email.
        </p>
        <a className="text-[12px] md:text-[22px] h-[12px] md:h-[36px] leading-normal font-light text-center text-white underline" href={`mailto:${email}`}>
            {email}
        </a>
    </VisibleTransition>;
export default ContactCard;
ContactCard.defaultProps = {
    className: ''
};