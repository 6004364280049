import { forwardRef } from 'react';
import TeamMainImage from "@/components/image/teamMainImage";
import TeamTitle from "@/components/text/teamTitle";
import VisibleTransition from "@/components/visibleTransition";
import { FormattedMessage } from "react-intl";
import TeamTimelineContainer from "@/components/container/teamTimelineContainer";
import TeamTimelineText from "@/components/text/teamTimelineText";
import CornerContainer from "../container/cornerContainer";

const UxSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div ref={ref}>
      <TeamMainImage type="ux" image="/images/team/ux_main.png" isUx={ true } />

      {/* 디지털 디자인 타임라인 */}
      {/*<div className="py-[40px] md:pt-[62px] md:pb-[85px]">*/}
      {/*  <TeamTitle title="team.ux.main.title" subTitle="team.ux.main.timeline" />*/}
      {/*  <div className="relative pt-[40px] md:pt-[80px] flex flex-col gap-10 justify-start items-start">*/}
      {/*    <hr className="absolute z-[-1] top-[0px] left-[7px] md:left-[13px] h-[83%] timeline-hr" />*/}

      {/*    <TeamTimelineContainer onGoing={false} type="ux.main.history1" year="2023">*/}
      {/*      <TeamTimelineText index={1} count={1} type="ux.main.history1" />*/}
      {/*      <TeamTimelineText index={2} count={1} type="ux.main.history1" />*/}
      {/*    </TeamTimelineContainer>*/}
      {/*    <TeamTimelineContainer onGoing={false} type="ux.main.history2" year="2019-2023">*/}
      {/*      <TeamTimelineText index={1} count={3} type="ux.main.history2" />*/}
      {/*    </TeamTimelineContainer>*/}
      {/*    <TeamTimelineContainer onGoing={false} type="ux.main.history3" year="2020-2023">*/}
      {/*      <TeamTimelineText index={1} count={1} type="ux.main.history3" />*/}
      {/*      <TeamTimelineText index={2} count={1} type="ux.main.history3" />*/}
      {/*    </TeamTimelineContainer>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* 디지털 디자인 강점 */}
      <div className="py-[40px] md:pt-[62px] md:pb-[85px] border-t border-t-[rgba(255,255,255,0.35)]">
        <TeamTitle title="team.ux.main.title" subTitle="team.ux.main.strength" />
        <ul className="pt-[40px] md:pt-[80px] flex flex-row flex-wrap justify-between gap-x-[30px] gap-y-[50px]">
          <CornerContainer style="flex-[1_1_45%] md:flex-[1_1_23%] h-[119px] md:h-[216px]">
            <div className="flex flex-col justify-center md:justify-around gap-[20px] md:gap-0 h-full text-[8px] md:text-[15px] font-light text-center">
              <h5 className="text-[20px] md:text-[35px] font-medium">
                <FormattedMessage id={`team.ux.main.strength.subtitle1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
              </h5>
              <div className="leading-[13px] md:leading-[22px]">
                <p><FormattedMessage id={`team.ux.main.strength.body1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body1_2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body1_3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body1_4`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} defaultMessage=" "/></p>
              </div>
            </div>
          </CornerContainer>
          <CornerContainer style="flex-[1_1_45%] md:flex-[1_1_23%] h-[119px] md:h-[216px]">
            <div className="flex flex-col justify-center md:justify-around gap-[20px] md:gap-0 h-full text-[8px] md:text-[15px] font-light text-center">
              <h5 className="text-[20px] md:text-[35px] font-medium">
                <FormattedMessage id={`team.ux.main.strength.subtitle2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
              </h5>
              <div className="leading-[13px] md:leading-[22px]">
                <p><FormattedMessage id={`team.ux.main.strength.body2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body2_2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body2_3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body2_4`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} defaultMessage=" "/></p>
              </div>
            </div>
          </CornerContainer>
          <CornerContainer style="flex-[1_1_45%] md:flex-[1_1_23%] h-[119px] md:h-[216px]">
            <div className="flex flex-col justify-center md:justify-around gap-[20px] md:gap-0 h-full text-[8px] md:text-[15px] font-light text-center">
              <h5 className="text-[20px] md:text-[35px] font-medium">
                <FormattedMessage id={`team.ux.main.strength.subtitle3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
              </h5>
              <div className="leading-[13px] md:leading-[22px]">
                <p><FormattedMessage id={`team.ux.main.strength.body3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body3_2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body3_3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
              </div>
            </div>
          </CornerContainer>
          <CornerContainer style="flex-[1_1_45%] md:flex-[1_1_23%] h-[119px] md:h-[216px]">
            <div className="flex flex-col justify-center md:justify-around gap-[20px] md:gap-0 h-full text-[8px] md:text-[15px] font-light text-center">
              <h5 className="text-[20px] md:text-[35px] font-medium">
                <FormattedMessage id={`team.ux.main.strength.subtitle4`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
              </h5>
              <div className="leading-[13px] md:leading-[22px]">
                <p><FormattedMessage id={`team.ux.main.strength.body4`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body4_2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body4_3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.ux.main.strength.body4_4`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} defaultMessage=" "/></p>
              </div>
            </div>
          </CornerContainer>
        </ul>
      </div>

      {/* UX 디자인 작업과정 */}
      <div className="py-[40px] md:pt-[62px] md:pb-[85px] border-t border-t-[rgba(255,255,255,0.35)]">
        <TeamTitle title="team.ux.main.title" subTitle="team.ux.main.work_process" />
        <ul className="pt-[40px] md:pt-[80px] grid md:grid-cols-3 bg-no-repeat bg-bottom bg-clip-text md:bg-clip-content"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/timeline.svg)`,
            }}
        >
          <VisibleTransition tag="li" className="flex flex-col mb-[30px] md:mb-0 w-[calc(100%-9px)]">
            <p className="mb-[8px] text-[14px] md:text-[30px] font-medium">
              <FormattedMessage id="team.ux.main.work_process.level1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
            </p>
            <div className="relative h-fit md:h-[267px]">
              <div className="relative md:pl-[8%] w-full h-full flex flex-col justify-center text-white">
                <h4 className="text-[20px] md:text-[27px] font-bold"><FormattedMessage id="team.ux.main.work_process.title1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></h4>

                <div className="mt-[20px] md:mt-[40px] text-[12px] md:text-[15px] font-light leading-[16px] md:leading-[22px]">
                  <p className={`${ localStorage.getItem('lang') === 'en' ? '' : 'mb-[15px]' }`}><FormattedMessage id="team.ux.main.work_process.body1_1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                  <p className="mb-[15px]"><FormattedMessage id="team.ux.main.work_process.body1_4" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} defaultMessage=" "/></p>
                  <p><FormattedMessage id="team.ux.main.work_process.body1_2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                  <p><FormattedMessage id="team.ux.main.work_process.body1_3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                </div>
              </div>
            </div>
          </VisibleTransition>

          <img className="mb-[30px] w-[14px] h-[8px] md:hidden" src={`${process.env.PUBLIC_URL}/images/team/process_step_icon.png`} alt="모바일 Next 아이콘" />

          <VisibleTransition tag="li" className="flex flex-col mb-[30px] md:mb-0 translate-x-[-9px] w-[calc(100% + 9px)]">
            <p className="mb-[8px] text-[14px] md:text-[30px] font-medium">
              <FormattedMessage id="team.ux.main.work_process.level2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
            </p>
            <div className="relative h-fit md:h-[267px]">
              <div className="relative md:pl-[15%] w-full h-full flex flex-col justify-center text-white">
                <h4 className="text-[20px] md:text-[27px] font-bold">
                  <FormattedMessage id="team.ux.main.work_process.title2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
                  <span className="block"><FormattedMessage id="team.ux.main.work_process.title2_1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} defaultMessage=" " /></span>
                </h4>

                <div className="mt-[20px] md:mt-[40px] text-[12px] md:text-[15px] font-light leading-[16px] md:leading-[22px]">
                  <p className="mb-[15px]"><FormattedMessage id="team.ux.main.work_process.body2_1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                  <p><FormattedMessage id="team.ux.main.work_process.body2_2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                  <p><FormattedMessage id="team.ux.main.work_process.body2_3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                </div>
              </div>
            </div>
          </VisibleTransition>

          <img className="mb-[30px] w-[14px] h-[8px] md:hidden" src={`${process.env.PUBLIC_URL}/images/team/process_step_icon.png`} alt="모바일 Next 아이콘" />

          <VisibleTransition tag="li" className="flex flex-col translate-x-[-18px] w-[calc(100% + 18px)]">
            <p className="mb-[8px] text-[14px] md:text-[30px] font-medium">
              <FormattedMessage id="team.ux.main.work_process.level3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
            </p>
            <div className="relative h-fit md:h-[267px]">
              <div className="relative md:pl-[15%] w-full h-full flex flex-col justify-center text-white">
                <h4 className="text-[20px] md:text-[27px] font-bold">
                  <FormattedMessage id="team.ux.main.work_process.title3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
                  <span className="block"><FormattedMessage id="team.ux.main.work_process.title3_1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} defaultMessage=" " /></span>
                </h4>

                <div className="mt-[20px] md:mt-[30px] text-[12px] md:text-[13px] font-light leading-[16px] md:leading-[22px]">
                  <p><FormattedMessage id="team.ux.main.work_process.body3_1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                  <p className="mb-[15px]"><FormattedMessage id="team.ux.main.work_process.body3_2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                  <p><FormattedMessage id="team.ux.main.work_process.body3_3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                  <p><FormattedMessage id="team.ux.main.work_process.body3_4" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
                </div>
              </div>
            </div>
          </VisibleTransition>
        </ul>
      </div>
    </div>
  )
});

export default UxSection;