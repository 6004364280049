import BorderText from "../text/borderText";
import { FormattedMessage } from "react-intl";
import CornerContainer from "./cornerContainer";

interface CompositionContainerProps {
  type: string;
  image: string;
  imageAlt: string;
  useHover: boolean;
  clickEvent?: any;
}

const CompositionContainer = ({ type, image, imageAlt, useHover, clickEvent }: CompositionContainerProps) => {
  return (
    <CornerContainer style={`flex-[1_1_40%] md:max-w-[274px] md:flex-auto  ${ useHover ? 'group' : '' }`}>
      <div className="pt-[10px] md:pt-[30px] relative flex flex-col justify-end h-full text-center" onClick={ clickEvent }>
        <p className="absolute top-0 right-0 hidden group-hover:block text-[15px] text-primary">Click to Team Detail</p>
        <BorderText style={`${localStorage.getItem('lang') === 'en' ? 'text-[28px] md:text-[56px]' : 'text-[22px] md:text-[40px]' }
                            mb-[11px] md:mb-[23px] font-medium group-hover:text-[43px] duration-500 leading-[normal]`}
                    text={<FormattedMessage id={`team.${type}.title`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>}
        />
        <div className="text-[8px] md:text-[13px] font-light leading-[12px] md:leading-[20px] tracking-wide">
          <p><FormattedMessage id={`team.${ type }.caption1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
          <p><FormattedMessage id={`team.${ type }.caption2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
          <hr className="my-[9px] md:my-[15px] mx-auto w-[40px] border-primary" />
          <p><FormattedMessage id={`team.${ type }.body1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
          <p><FormattedMessage id={`team.${ type }.body2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
          <p><FormattedMessage id={`team.${ type }.body3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
          <img className="mt-[10px] md:mt-[15px] w-full md:h-[265px]" src={ image } alt={ imageAlt } />
        </div>
      </div>
    </CornerContainer>
  )
};

export default CompositionContainer;