import { FormattedMessage } from "react-intl";

interface TeamTitleProps {
  title: string;
  subTitle: string
}

const TeamTitle = ({ title, subTitle }: TeamTitleProps) => {
  return (
    <h5 className="text-center leading-[17px] md:leading-8 font-medium">
      <p className="text-[12px] md:text-[22px] text-gray100"><FormattedMessage id={ title } values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
      <p className="text-[16px] md:text-[30px]"><FormattedMessage id={ subTitle } values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
    </h5>
  )
};

export default TeamTitle;