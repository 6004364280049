interface BorderTextProps {
	style: string;
	text: React.JSX.Element | string;
}

// Text Size Props 필수
const BorderText = ({ style, text }: BorderTextProps) => {
	return <span className={`${ style } text-white text-border-red`}>{ text }</span>
};

export default BorderText;