import scrollbarController from "@/scrollbarController";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ScrollStatus } from "smooth-scrollbar/interfaces";

// 상단에 적용할 스크롤 상태입니다.
const FixedScrollStatus = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const scrollListener = ({ limit, offset }: ScrollStatus) => {
            const scrollbar = scrollbarController.getScrollbar();
            if (!scrollbar) return;
            const { container: { width: containerWidth, height: containerHeight }, content: { height: contentHeight } } = scrollbar.getSize();
            setWidth(containerWidth * offset.y / (contentHeight - containerHeight));
        };

        const resizeListener = (e: Event, ) => {
            const scrollbar = scrollbarController.getScrollbar();
            if (!scrollbar) return;
            const offset = scrollbar.offset;
            const { container: { width: containerWidth, height: containerHeight }, content: { height: contentHeight } } = scrollbar.getSize();
            setWidth(containerWidth * offset.y / (contentHeight - containerHeight)); 
        };

        scrollbarController.addListener(scrollListener);
        window.addEventListener('resize', resizeListener);
        return () => {
            scrollbarController.removeListener(scrollListener);
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return createPortal(<div className="fixed pointer-events-none top-0 left-0 w-full h-[4px] z-10">
        <div className="w-0 h-full bg-[#868686]"
            style={{
                width: `${width}px`,
            }}
        ></div>
    </div>, document.getElementById("root")!);
};
export default FixedScrollStatus;