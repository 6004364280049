import { forwardRef } from 'react';

import TeamMainImage from "@/components/image/teamMainImage";
import TeamTitle from "@/components/text/teamTitle";
import VisibleTransition from "@/components/visibleTransition";
import { FormattedMessage } from "react-intl";
import TeamTimelineContainer from "@/components/container/teamTimelineContainer";
import TeamTimelineText from "@/components/text/teamTimelineText";
import CornerContainer from "../container/cornerContainer";
import DegitalProcess from "@/components/team/degitalProcess";

const DegitalSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div ref={ref}>
      <TeamMainImage type="degital" image="/images/team/degital_main.png" />

      {/* 디지털 디자인 타임라인 */}
      {/*<div className="py-[40px] md:pt-[62px] md:pb-[85px]">*/}
      {/*  <TeamTitle title="team.degital.main.title" subTitle="team.degital.main.timeline" />*/}
      {/*  <div className="relative pt-[40px] md:pt-[80px] flex flex-col gap-10 justify-start items-start">*/}
      {/*    <hr className="absolute z-[-1] top-[0px] left-[7px] md:left-[13px] h-[81%] md:h-[85%] timeline-hr" />*/}

      {/*    <TeamTimelineContainer onGoing={true} type="degital.main.history1" year="2019-2024">*/}
      {/*      <TeamTimelineText index={1} count={3} type="degital.main.history1" />*/}
      {/*      <TeamTimelineText index={2} count={3} type="degital.main.history1" />*/}
      {/*    </TeamTimelineContainer>*/}
      {/*    <TeamTimelineContainer onGoing={false} type="degital.main.history2" year="2020-2022">*/}
      {/*      <TeamTimelineText index={1} count={2} type="degital.main.history2" />*/}
      {/*    </TeamTimelineContainer>*/}
      {/*    <TeamTimelineContainer onGoing={false} type="degital.main.history3" year="2020-2023">*/}
      {/*      <TeamTimelineText index={1} count={1} type="degital.main.history3" />*/}
      {/*      <TeamTimelineText index={2} count={1} type="degital.main.history3" />*/}
      {/*    </TeamTimelineContainer>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* 디지털 디자인 강점 */}
      <div className="py-[40px] md:pt-[62px] md:pb-[85px] border-t border-t-[rgba(255,255,255,0.35)]">
        <TeamTitle title="team.degital.main.title" subTitle="team.degital.main.strength" />
        <ul className="pt-[40px] md:pt-[80px] flex flex-col flex-wrap items-center justify-center">
          <CornerContainer style="w-full md:w-1/2 h-[119px] md:h-[216px]">
            <div className="flex flex-col justify-around h-full text-[8px] md:text-[15px] font-light text-center">
              <h5 className="text-[20px] md:text-[27px] md:text-[35px] font-medium">
                <FormattedMessage id={`team.degital.main.strength.subtitle1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
              </h5>
              <div className="leading-[13px] md:leading-[22px]">
                <p><FormattedMessage id={`team.degital.main.strength.body1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.degital.main.strength.body1_2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
                <p><FormattedMessage id={`team.degital.main.strength.body1_3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></p>
              </div>
            </div>
          </CornerContainer>
        </ul>
      </div>

      {/* 디지털 디자인 작업방식 */}
      <div className="py-[40px] md:pt-[62px] md:pb-[85px] border-t border-t-[rgba(255,255,255,0.35)]">
        <TeamTitle title="team.degital.main.title" subTitle="team.degital.main.work" />
        <ul className="py-[40px] md:py-[80px] flex flex-col md:flex-row flex-wrap gap-x-[20px] gap-y-[80px] items-center justify-around">
          <DegitalProcess id="team.degital.main.work.detail1" index="1" processNumber="40,000"/>
          <DegitalProcess id="team.degital.main.work.detail2" index="2" processNumber="500,000"/>
          <DegitalProcess id="team.degital.main.work.detail3" index="3" processNumber="4,500,000"/>
        </ul>
      </div>

      {/* 디지털 디자인 업무과정 */}
      <div className="pt-[40px] pb-[125px] md:pt-[62px] md:pb-[220px] border-t border-t-[rgba(255,255,255,0.35)]">
        <TeamTitle title="team.degital.main.title" subTitle="team.degital.main.business_process" />
        <ul className="pt-[40px] md:pt-[80px] grid md:grid-cols-3 bg-no-repeat bg-bottom bg-clip-text md:bg-clip-content"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/timeline.svg)`,
            }}
        >
          <VisibleTransition tag="li" className="flex flex-col mb-[30px] md:mb-0 w-[calc(100%-9px)]">
            <p className="mb-[8px] text-[14px] md:text-[30px] font-medium">
              <FormattedMessage id="team.degital.main.business_process.level1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
            </p>
            <div className="relative h-fit md:h-[267px]">
              <div className="relative md:pl-[8%] md:pr-[10%] w-full h-full flex flex-col justify-center">
                <h4 className="text-[20px] md:text-[22px] leading-normal font-bold"><FormattedMessage id="team.degital.main.business_process.title1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></h4>
                <p className="mt-[15px] text-[12px] md:text-[15px] font-light leading-[16px] md:leading-[22px]"><FormattedMessage id="team.degital.main.business_process.body1_1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>

                <div className="mt-[15px] md:mt-[20px] md:min-h-[115px] flex md:justify-end items-center">
                  <img className="w-[41px] h-[42px] md:w-[84px] md:h-[85px]" src={`${process.env.PUBLIC_URL}/images/team/degital_process_icon1.png`} alt="디지털 디자인 업무과정 아이콘" />
                  <img className="w-[57px] h-[57px] md:w-[115px] md:h-[115px]" src={`${process.env.PUBLIC_URL}/images/team/degital_process_icon2.png`} alt="디지털 디자인 업무과정 아이콘" />
                  <img className="w-[48px] h-[39px] md:w-[96px] md:h-[79px]" src={`${process.env.PUBLIC_URL}/images/team/degital_process_icon3.png`} alt="디지털 디자인 업무과정 아이콘" />
                </div>
              </div>
            </div>
          </VisibleTransition>

          <img className="mb-[30px] w-[14px] h-[8px] md:hidden" src={`${process.env.PUBLIC_URL}/images/team/process_step_icon.png`} alt="모바일 Next 아이콘" />

          <VisibleTransition tag="li" className="flex flex-col mb-[30px] md:mb-0 translate-x-[-9px] w-[calc(100% + 9px)]">
            <p className="mb-[8px] text-[14px] md:text-[30px] font-medium">
              <FormattedMessage id="team.mobility.main.business_process.level2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
            </p>
            <div className="relative h-fit md:h-[267px]">
              <div className="relative md:pl-[15%] md:pr-[10%] w-full h-full flex flex-col justify-center">
                <h4 className="text-[20px] md:text-[27px] font-bold"><FormattedMessage id="team.degital.main.business_process.title2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></h4>
                <p className="mt-[15px] text-[12px] md:text-[15px] font-light leading-[16px] md:leading-[22px]"><FormattedMessage id="team.degital.main.business_process.body2_1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>

                <div className="mt-[20px] md:mt-[25px] md:min-h-[115px] flex md:justify-end items-center gap-5">
                  <img className="w-[41px] h-[42px] md:w-[83px] md:h-[85px]" src={`${process.env.PUBLIC_URL}/images/team/degital_process_icon4.png`} alt="디지털 디자인 업무과정 아이콘" />
                  <img className="w-[41px] h-[42px] md:w-[83px] md:h-[85px]" src={`${process.env.PUBLIC_URL}/images/team/degital_process_icon5.png`} alt="디지털 디자인 업무과정 아이콘" />
                </div>
              </div>
            </div>
          </VisibleTransition>

          <img className="mb-[30px] w-[14px] h-[8px] md:hidden" src={`${process.env.PUBLIC_URL}/images/team/process_step_icon.png`} alt="모바일 Next 아이콘" />

          <VisibleTransition tag="li" className="flex flex-col translate-x-[-18px] w-[calc(100% + 18px)]">
            <p className="mb-[8px] text-[14px] md:text-[30px] font-medium">
              <FormattedMessage id="team.mobility.main.business_process.level3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
            </p>
            <div className="relative h-fit md:h-[267px]">
              <div className="relative md:pl-[15%] md:pr-[10%] w-full h-full flex flex-col justify-center">
                <h4 className="text-[20px] md:text-[27px] font-bold"><FormattedMessage id="team.degital.main.business_process.title3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></h4>
                <p className="mt-[15px] text-[12px] md:text-[15px] font-light leading-[16px] md:leading-[22px]"><FormattedMessage id="team.degital.main.business_process.body3_1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>

                <div className="mt-[20px] md:mt-[25px] md:min-h-[115px] flex md:justify-end items-center gap-5">
                  <img className="w-[54px] h-[41px] md:w-[112px] md:h-[84px]" src={`${process.env.PUBLIC_URL}/images/team/degital_process_icon6.png`} alt="디지털 디자인 업무과정 아이콘" />
                </div>
              </div>
            </div>
          </VisibleTransition>
        </ul>
      </div>
    </div>
  )
});

export default DegitalSection;