import VisibleTransition from "@/components/visibleTransition";

const CornerContainer = ({ style, children }) => {
	return (
		<VisibleTransition tag="li" className={`relative ${ style }`}>
			{/*<div className="absolute scale-100 group-hover:scale-110 transition-all duration-200 w-0 h-0 border-[8px] md:border-[12px]*/}
			{/*								border-l-primary border-t-primary text-[transparent] rounded-tr-[3px] rounded-bl-[3px] md:rounded-tr-[5px] md:rounded-bl-[5px]*/}
			{/*								group-hover:border-[14px]"*/}
			{/*/>*/}
			{/*<div className="z-[99] absolute bottom-[0px] right-[0px] scale-100 group-hover:scale-110 transition-all duration-200 w-0 h-0 border-[8px] md:border-[12px]*/}
			{/*								border-b-gray100 border-r-gray100 text-[transparent] rounded-tr-[3px] rounded-bl-[3px] md:rounded-tr-[5px] md:rounded-bl-[5px]*/}
			{/*								group-hover:border-[14px]"*/}
			{/*/>*/}
			<img className="z-[99] absolute w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={`${process.env.PUBLIC_URL}/images/triangle_red.svg`} alt="triangle_red" />
			<img className="z-[99] absolute bottom-0 right-0 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={`${process.env.PUBLIC_URL}/images/triangle_gray.svg`} alt="triangle_gray" />
			{ children }
		</VisibleTransition>
	)
};

export default CornerContainer;