import ContactCard from "@/components/contact/contactCard";
import LineText from "@/components/text/lineText";
import VisibleTransition from "@/components/visibleTransition";
import { FormattedMessage } from "react-intl";

const ContactPage = () => {
	return <div className="py-m-y md:py-w-y px-m-x md:px-w-x max-w-wide w-full m-auto">
		<VisibleTransition className="mb-m-title md:mb-w-title">
			<h3 className="mb-m-top-title md:mb-w-top-title leading-m-title md:leading-w-title text-m-title md:text-w-title font-m-title md:font-w-title text-white md:text-center">
				CONTACT US
			</h3>
			{/** MO */}
			<p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
				<FormattedMessage id="m.contact.body1" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
				<FormattedMessage id="m.contact.body2" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
				<FormattedMessage id="m.contact.body3" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
				<FormattedMessage id="m.contact.body4" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>

			{/** PC */}
			<p className="hidden md:block leading-w-caption text-w-caption font-w-caption text-center">
				<FormattedMessage id="contact.body1" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<p className="hidden md:block leading-w-caption text-w-caption font-w-caption text-center">
				<FormattedMessage id="contact.body2" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
		</VisibleTransition>

		<LineText text="OFFICE INFORMATION" />
		{/* 
		<div className="mt-[20px] flex flex-row items-center gap-[16px] md:gap-[40px]">
			<hr className="hidden md:block flex-auto border-primary" />
			<p className="text-[12px] leading-[30px] md:text-[20px] tracking-[.25em] md:leading-[30px] font-semibold">
				OFFICE INFORMATION
			</p>
			<hr className="flex-auto border-primary" />
		</div> */}

		<VisibleTransition className="mb-[40px] md:mb-[93px] mt-[24px] md:mt-[70px]">
			<h5 className="text-[16px] md:text-[32px] mb-[4px] md:mb-[18px] md:text-center font-medium">
				<FormattedMessage id="contact.address.title" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
			</h5>
			<p className="text-m-office-desc md:text-[22px] leading-m-office-desc md:leading-[44px] md:text-center font-light">
				Tel. <a href="tel:070-7720-7600">070-7720-7600</a>
			</p>
			<p className="text-m-office-desc md:text-[22px] leading-m-office-desc md:leading-[44px] md:text-center font-light">
				E-mail : <a className="underline" href={"mailto:jbpark@studiov2v.com"}>jbpark@studiov2v.com</a>
			</p>
			<p className="text-m-office-desc md:text-[22px] leading-m-office-desc md:leading-[44px] md:text-center font-light">
				<FormattedMessage id="contact.address.detail" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
			</p>
			<img className="hidden md:block mt-[55px]" src={`${process.env.PUBLIC_URL}/images/map_${localStorage.getItem('lang') ?? 'ko'}.png`} alt='지도' />
			<img className="block md:hidden mt-m-office" src={`${process.env.PUBLIC_URL}/images/ic_m_map_${localStorage.getItem('lang') ?? 'ko'}.png`} alt='지도' />
		</VisibleTransition>

		<LineText text="CONTACT BY TEAM" />
		{/* <div className="mt-[20px] md:mt-[60px] flex flex-row items-center gap-[16px] md:gap-[40px]">
			<hr className="hidden md:block flex-auto border-primary" />
			<p className="text-[12px] leading-[30px] md:text-[20px] tracking-[.25em] md:leading-[30px] font-semibold">
				CONTACT BY TEAM
			</p>
			<hr className="flex-auto border-primary" />
		</div> */}

		<div className="flex flex-row flex-wrap mt-[40px] md:mt-[95px] gap-y-[50px] xl:grid xl:grid-cols-contart">
			<ContactCard
				className="min-[354px]:border-r-[1px] border-primary"
				position={<FormattedMessage id="contact.people1.position" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />}
				email="minho@studiov2v.com"
			/>
			<ContactCard
				className="xl:border-r-[1px] md:border-primary"
				position={<FormattedMessage id="contact.people2.position" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />}
				email="donghun26@studiov2v.com"
			/>
			<ContactCard
				className="min-[354px]:border-r-[1px] border-primary"
				position={<FormattedMessage id="contact.people3.position" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />}
				email="gpens@studiov2v.com"
			/>
			<ContactCard
				position={<FormattedMessage id="contact.people4.position" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />}
				email="jade27@studiov2v.com"
			/>
		</div>
	</div>;
};
export default ContactPage;