import React from "react";
import { Link } from "react-router-dom";

interface HomeSlideImageProps {
	imageUrl: string;
	link: React.JSX.Element | any;
}

const HomeSlideImage = ({ imageUrl, link }: HomeSlideImageProps) => {
	return (
		<Link to={`/portfolio${link}`}>
			<div className="absolute top-0 z-[99] w-full h-full bg-[linear-gradient(0deg,#0C0C0C_5%,rgba(12,12,12,0.02)_40%)]" />
			<div className={`relative h-full bg-cover bg-center`}
					 style={{backgroundImage: `url('${imageUrl}')`}}
			>
			</div>
		</Link>
	)
};

export default HomeSlideImage;