interface HistoryTextProps {
	year: string;
	mYear?: string;
	com: string | React.JSX.Element;
	text: string;
}

const HistoryText = ({ year, com, text }: HistoryTextProps) => {
	return (
		<div className="flex items-start leading-[20px] md:leading-[30px]">
			<span className="flex-[1_1_15%] text-[9px] md:text-[22px] md:leading-[30px] font-normal">{ year }</span>
			<span className="flex-[1_1_10%] text-[22px] leading-[30px] text-gray100 font-medium hidden md:block uppercase">{ com }</span>
			<span className="flex-[1_1_75%] col-span-3 md:col-span-2 text-[9px] md:text-[20px] font-light">{ text }</span>
		</div>
	)
};

export default HistoryText;