
import Scrollbar from 'smooth-scrollbar';
type Data2d = {
    x: number;
    y: number;
};

export type ScrollStatus = {
    offset: Data2d;
    limit: Data2d;
};

class ScrollbarController {
    private scrollbar!: Scrollbar;

    init(scrollbar: Scrollbar) {
        this.scrollbar = scrollbar;
    }

    getScrollbar() {
        return this.scrollbar;
    }

    destory() {
        this.scrollbar && this.scrollbar.destroy();
    }

    async scrollIntoView(element: HTMLElement) {
        if (typeof this.scrollbar !== "object") {
            await this.waitLoading();
            this.scrollIntoView(element);
            return;
        }
        this.scrollbar.scrollIntoView(element);
    }

    async scrollTo(x?: number | undefined, y?: number | undefined, duration?: number | undefined) {
        if (typeof this.scrollbar !== "object") {
            await this.waitLoading();
            this.scrollTo(x, y, duration);
            return;
        }
        this.scrollbar.scrollTo(x, y, duration);
    }

    async addListener(fn: (status: ScrollStatus) => void) {
        if (typeof this.scrollbar !== "object") {
            await this.waitLoading();
            this.addListener(fn);
            return;
        }
        this.scrollbar.addListener(fn);
    }

    async removeListener(fn: (status: ScrollStatus) => void) {
        if (typeof this.scrollbar !== "object") {
            await this.waitLoading();
            this.removeListener(fn);
            return;
        }
        this.scrollbar.removeListener(fn);
    }

    private waitLoading() {
        return new Promise((resolve) => window.setTimeout(resolve, 500));
    }
}

const scrollbarController = new ScrollbarController();
export default scrollbarController;