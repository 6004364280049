import React, { useEffect, useState, useRef } from "react";
import { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { debounce } from 'lodash';
import HomeSlideImage from "../../components/image/homeSlideImage";
import VisibleTransition from "@/components/visibleTransition";
import Footer from "@/components/layout/footer";
import { Swiper as TypeSwiper } from "swiper/types";
import FixedNavigator from "@/components/fixedNavigator";
import { FormattedMessage } from "react-intl";
import { HomeSlideData } from "@/defines/home";
import { getHomeSlideData } from "@/api/work";



const HomePage = () => {
  const swiperRef = useRef<TypeSwiper>();
  const [width, setWidth] = useState(0);
  const [activeIndex, setActiveIndex] = useState(1);
  const [homeSlides, setHomeSlides] = useState<HomeSlideData[]>([]);

  const handleResize = debounce(() => {
    setWidth(window.innerWidth);
  }, 100);

  useEffect(() => {
    getHomeSlideData().then(setHomeSlides);
    setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const prevPage = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;
    swiper.autoplay.stop();
    swiper.slidePrev();
    swiper.autoplay.start();
  };

  const nextPage = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;
    swiper.autoplay.stop();
    swiper.slideNext();
    swiper.autoplay.start();
  };

  return <VisibleTransition className="relative z-0">
    <Swiper
      loop={true}
      cssMode={true}
      navigation={false}
      pagination={true}
      mousewheel={true}
      direction={width >= 768 ? 'horizontal' : 'vertical'}
      autoplay={{ delay: 5000, disableOnInteraction: false }}
      modules={[Navigation, Pagination, Mousewheel, Autoplay]}
      className={`mySwiper h-[calc(100dvh-44px)] md:h-[calc(100dvh-100px)]`}
      onSlideChange={(swiper: TypeSwiper) => setActiveIndex(swiper.realIndex + 1)}
      onSwiper={(swiper: TypeSwiper) => swiperRef.current = swiper}
    >
      {homeSlides?.map((v, i) => (
        <SwiperSlide key={"home_slide" + i}>
          <HomeSlideImage imageUrl={ width >= 768 ? v.imageUrl_w : v.imageUrl_m } link={ v.link }/>
        </SwiperSlide>))}
    </Swiper>

    <FixedNavigator
      onNextPage={nextPage}
      onPrevPage={prevPage}
    />
    <div className="absolute text-center w-full left-[50%] bottom-[90px] md:bottom-[130px] translate-x-[-50%] z-10">
      {/*<p className="font-medium text-[16px] md:text-[24px]"><FormattedMessage id={`home.work${activeIndex}.company`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>*/}
      {/*<h4 className="mt-[5px] mb-[10px] md:mb-[20px] font-medium text-[20px] md:text-[40px]"><FormattedMessage id={`home.work${activeIndex}.title`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></h4>*/}
      {/*<p className="mb-[5px] font-light text-[15px] hidden md:block"><FormattedMessage id={`home.work${activeIndex}.body1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>*/}
      {/*<p className="font-light text-[12px] md:text-[15px]"><FormattedMessage id={`home.work${activeIndex}.body2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>*/}
      <p className="font-medium text-[16px] md:text-[24px]">{ localStorage.getItem('lang') === 'ko' ? homeSlides[activeIndex - 1]?.company_ko : homeSlides[activeIndex - 1]?.company_en }</p>
      <h4 className="mt-[5px] mb-[10px] md:mb-[20px] font-medium text-[20px] md:text-[40px]">{ localStorage.getItem('lang') === 'ko' ? homeSlides[activeIndex - 1]?.title_ko : homeSlides[activeIndex - 1]?.title_en  }</h4>
      <p className="mb-[5px] font-light text-[15px] hidden md:block">{ localStorage.getItem('lang') === 'ko' ? homeSlides[activeIndex - 1]?.body1_ko : homeSlides[activeIndex - 1]?.body1_en }</p>
      <p className="font-light text-[12px] md:text-[15px]">{ localStorage.getItem('lang') === 'ko' ? homeSlides[activeIndex - 1]?.body2_ko : homeSlides[activeIndex - 1]?.body2_en }</p>
    </div>
    <Footer // home 한정으로 Footer absolute 처리
      className={`absolute bottom-0 left-0 w-full z-10 bg-gradient-to-b from-transparent to-black`}
    />
  </VisibleTransition>;
};
export default HomePage;