import { useState, useRef, useEffect } from 'react';

enum LocaleType {
    ENG = 0,
    KOR = 1,
}

const locale = localStorage.getItem('lang') ?? 'ko';

interface LangSelectorProps {
    className?: string;
}

/// 로케일 변경 네비게이터
const LangSelector = ({ className }: LangSelectorProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [display, setDisplay] = useState(false);
    const updateNavigator = (e: React.MouseEvent<HTMLUListElement>) => {
        let target = e.target as HTMLElement | null;
        while (target && target.nodeName !== "LI" && target.nodeName === "BODY") {
            target = target.parentElement;
        }
        if (!target) return;

        const value = (target as HTMLLIElement).value as LocaleType;
        switch (value) {
            case LocaleType.KOR:
                localStorage.setItem('lang', 'ko');
                break;
            case LocaleType.ENG:
                localStorage.setItem('lang', 'en');
                break;
        }
        window.location.reload();
    };

    const getLocale = () => {
        switch (locale) {
            case 'ko':
                return 'KOR';
            case 'en':
                return 'ENG';
        }
    };

    useEffect(() => { /// focus / focusout 처리
        const callback = (e: MouseEvent) => {
            let target = (e.target as HTMLElement);
            while (target !== ref.current) {
                if (target.parentElement)
                    target = target.parentElement;
                else
                    break;
            }
            setDisplay(prev => target === ref.current ? !prev : false);
        };
        document.body.addEventListener('click', callback);
        return () => document.body.removeEventListener('click', callback)
    }, [setDisplay]);

    return <div ref={ref} className={`relative md:w-[119px] text-gray100 md:text-white bg-black ${className}`} >
        <div /// PC용
            className="hidden md:flex items-center justify-center gap-[8px] md:pr-[24px] cursor-pointer text-[14px] leading-[17.5px] tracking-w-default font-medium"
        >
            <span className='flex-auto text-right'>{getLocale()}</span>
            <img src={`${process.env.PUBLIC_URL}/images/polygon_pc.svg`} className={`relative top-[-1px] rotate-0 ${display ? 'rotate-90' : ''} transition-all`} alt='' />
        </div>
        <div /// 모바일용
            className="flex md:hidden gap-[4px] px-[10px] cursor-pointer items-center font-medium text-[11px] tracking-[1.76px]"
        >
            <img src={`${process.env.PUBLIC_URL}/images/polygon_mo.svg`} className={`relative top-[-1px] rotate-0 ${display ? 'rotate-90' : ''} transition-all`} alt='' />
            <span className='text-left'>{getLocale()}</span>
        </div>
        <ul
            className={`absolute pr-[10px] pl-[20px] md:p-0 mt-[7px] md:mt-[12px] tracking-[1.76px] md:tracking-w-default w-full text-left md:text-right rounded-lg top-[100%] left-0 bg-black shadow-xl transition-all cursor-pointer text-[11px] md:text-[14px] md:leading-[17.5px] font-medium ${display ? 'opacity-100' : 'opacity-0 pointer-events-none'} ${window.localStorage.getItem('lang') === 'en' ? 'md:pr-[calc(24px+8px+10px)]' : 'md:pr-[calc(24px+8px+8px)]'}`}
            onClick={updateNavigator}
        >
            <li className={locale === 'ko' ? 'block' : 'hidden'} value={LocaleType.ENG}>ENG</li>
            <li className={locale === 'en' ? 'block' : 'hidden'} value={LocaleType.KOR}>KOR</li>
        </ul>
    </div>;
};
export default LangSelector;
LangSelector.defaultProps = {
    className: ''
};