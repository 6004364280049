import { useLayoutEffect, useState } from "react";
import { createPortal } from 'react-dom';
import { Link, NavLink,
    // useLocation
} from "react-router-dom";
import { HeaderNavbars } from "./header";
// import Footer from "./footer";
// import VisibleTransition from "../visibleTransition";

const SideMenuList: Record<string, [string, string][]> = {
    "TEAM": [
        ["STYLE", "#style"],
        ["CAS", "#cas"],
        ["UX", "#ux"],
    ],
    "WORK": [
        ["AUTO", "#auto"],
        ["CART", "#cart"],
    ]
};

const SideMenu = () => {
    // const { pathname, hash } = useLocation();

    const [display, setDisplay] = useState(false);
    // const [subMenuName, setSubMenuName] = useState<string | null>(() => {
    //     const index = HeaderNavbars.findIndex(([name, to]) => pathname === to);
    //     if (index === -1) return null;
    //     return HeaderNavbars[index][0];
    // });
    const [subMenuName, setSubMenuName] = useState<string | null>(null);


    const openMenu = () => {
        setDisplay(true);
    };
    const closeMenu = () => {
        setDisplay(false);
    };

    useLayoutEffect(() => {
        // setSubMenuName(() => {
        //     const index = HeaderNavbars.findIndex(([name, to]) => pathname === to);
        //     if (index === -1) return null;
        //     return HeaderNavbars[index][0];
        // });
        setSubMenuName(null);
    }, [display]);

    return <div className="block md:hidden">
        <button onClick={openMenu}>
            <img src={`${process.env.PUBLIC_URL}/images/ic_menu.png`} className="h-[12pt]" />
        </button>

        {createPortal(<div className={`fixed z-50 flex-col left-0 top-0 flex w-[100vw] h-[100vh] ${display ? 'translate-x-0' : 'translate-x-[100%]'} transition-all bg-black`}>
            <header className="w-full h-[44px] pl-[16pt] pr-[16pt] border-b-[1px] border-b-gray100">
                <nav className="flex gap-1 items-center w-full h-full">
                    <Link to="/" className="flex-auto cursor-pointer justify-start">
                        <img src={`${process.env.PUBLIC_URL}/V2V_Logo_White.svg`} className="h-[19pt] md:h-auto" />
                    </Link>
                    <button onClick={closeMenu}>
                        <img src={`${process.env.PUBLIC_URL}/ic_closed.png`} className="h-[12pt]" />
                    </button>

                </nav>
            </header>
            <ul className="items-start w-[50vw] flex-auto text-white mt-[23px]">
                {HeaderNavbars.map(([name, to]) => {
                    const sublist = SideMenuList[name] || [];
                    return <li key={`header-navigator-${name}`} className="w-full flex-auto border-b-white border-opacity-20 [&:not(:last-child)]:border-b-[1px] px-[15px] py-[22px]">
                        {sublist.length !== 0 ? <span
                            className="relative flex items-center gap-[15px] h-full"
                            onClick={(e) => {
                                e.preventDefault();
                                setSubMenuName(prev => prev === name ? null : name);
                            }}>
                            <NavLink to={to} onClick={closeMenu} className={`text-[16px] flex-[0_0_64px] leading-normal font-medium tracking-[2.56px]`}>
                                {name}
                            </NavLink>
                            <img className={`transition-transform ${subMenuName === name ? 'rotate-0' : 'rotate-90'}`} src={`${process.env.PUBLIC_URL}/images/m_menu_arrow.svg`} />
                        </span> : <NavLink to={to} onClick={closeMenu} className="block h-full">
                            <span className={`text-[16px] leading-normal font-medium tracking-[2.56px]`}>
                                {name}
                            </span>
                        </NavLink>}
                        {sublist.length !== 0 && <ul className={`transition-all h-0 ${subMenuName === name ? 'mt-[16px]' : 'mt-0'} overflow-hidden`}
                            style={{
                                height: subMenuName === name ? `${((sublist.length * 24) + (Math.max(sublist.length - 1, 0) * 12))}px` : '0px'
                            }}
                        >
                            {sublist.map(([sidename, sideTo]) => <li className="[&:not(:last-child)]:mb-[12px]" key={`header-navigator-${name}-${sidename}`}>
                                <NavLink to={`${to}${sideTo}`} onClick={closeMenu} className="block h-full">
                                    <span className="text-[12px] font-normal tracking-[1.92px] opacity-60">
                                        {sidename}
                                    </span>
                                </NavLink>
                            </li>)}
                        </ul>}
                    </li>
                })}
            </ul>
            <footer className="w-full bg-black px-[15px] py-[45px]">
                <span className="font-medium text-gray400 text-[10px]">
                    ⓒ 2024 StudioV2V
                </span>
            </footer>
        </div>, document.getElementById('root')!)
        }
    </div >
};
export default SideMenu;