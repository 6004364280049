import { useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import VisibleTransition from "@/components/visibleTransition";
import scrollbarController from "@/scrollbarController";
import FixedNavigator from "@/components/fixedNavigator";
import { WorkAutoData } from "@/defines/work";
import { getWorkAutos } from "@/api/work";
import { createPortal } from "react-dom";

const PortfolioDetailPage = () => { // 포트폴리오 상세 페이지(Work > Auto)
	const { id = '' } = useParams<{ id: string; }>();
	const [uid, setUID] = useState(id);
	const [visibleItems, setVisibleItems] = useState<string[]>([]);
	const [list, setList] = useState<WorkAutoData[]>([]);
	const topRef = useRef<HTMLDivElement>(null);

	const item = useMemo(() => list.find(({ id }) => id === uid), [list, uid]);

	const nextPage = () => {
		const currentIndex = list.findIndex(({ id }) => id === uid);
		let nextIndex = currentIndex + 1 >= list.length ? 1 : currentIndex + 1;

		if (list[nextIndex].id === 'cart') nextIndex = currentIndex + 2 >= list.length ? 1 : currentIndex + 2;

		setUID(list[nextIndex].id);
		setVisibleItems([]);

		// scrollbarController.scrollTo(0);
		scrollbarController.scrollIntoView(topRef.current!);
	};

	const prevPage = () => {
		const currentIndex = list.findIndex(({ id }) => id === uid);
		let prevIndex = currentIndex - 1 < 0 ? list.length - 1 : currentIndex - 1;

		if (list[prevIndex].id === 'cart') prevIndex = currentIndex - 2 < 0 ? list.length - 1 : currentIndex - 2;

		setUID(list[prevIndex].id);
		setVisibleItems([]);

		scrollbarController.scrollIntoView(topRef.current!);
		// scrollbarController.scrollTo(0);
	};

	useEffect(() => {
		getWorkAutos().then(setList);
	}, []);

	if (!item) return <></>;

	const client = item[`client_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`]
	const title = item[`title_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`]
	const description1 = item[`description1_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`]
	const description2 = item[`description2_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`]
	return <div className="py-m-y md:py-[109px] max-w-wide w-full m-auto px-m-x md:px-w-x" ref={topRef}>
		<VisibleTransition /// 상단
			className="mb-w-title"
		>
			<p className="text-[16px] h-[28px] leading-[20px] md:text-[24px] md:leading-[15px] font-medium md:text-center">
				{client}
			</p>
			<h3 className="text-[24px] md:text-[40px] md:mt-[8px] font-medium md:text-center">
				{title}
			</h3>
			<p className="text-[10px] leading-[16px] md:text-[15px] md:leading-[28px] mt-[8px] md:mt-[30px] font-light md:text-center">
				{description1}
			</p>
			<p className="text-[10px] leading-[16px] md:text-[15px] md:leading-[19px] mt-[8px] md:mt-[30px] font-light md:text-center">
				{description2}
			</p>
			<p className="text-[10px] leading-[16px] md:text-[15px] md:leading-[19px] mt-[4px] md:mt-[10px] md:text-center">
				{item.date}
			</p>
		</VisibleTransition>

		<ul /// 리스트영역
			className="flex flex-col items-center mt-[70px] md:mt-[100px] gap-[20px] md:gap-[40px]"
		>
			{item.detail_images.map((imageUrl) => {
				return <VisibleTransition
					tag="li"
					key={`portfolio-detail-${id}-${imageUrl}`}
					visible={visibleItems.includes(id)}
					className="flex-auto group relative truncate"
				>
					<img
						src={`${process.env.PUBLIC_URL}/${imageUrl}`}
						onLoad={() => setVisibleItems(prev => prev.concat([id]))}
						alt="포트폴리오_상세_아이템"
					/>
				</VisibleTransition>;
			})}
		</ul>

		<FixedNavigator
			onPrevPage={prevPage}
			onNextPage={nextPage}
		/>

		{createPortal(<VisibleTransition position="-50%" className={`flex fixed z-[999] bottom-0 right-[15px] w-[40px] h-[40px] md:w-[64px] md:h-[64px] items-center justify-center gap-[58px] md:py-[30px] md:px-[6px] bg-black border border-primary rounded-md`}>
			<button className="group" onClick={() => scrollbarController.scrollIntoView(topRef.current!)}>
				<svg width="36" height="18" viewBox="0 0 36 18" className={`fill-white group-hover:fill-primary transition-all w-[20px] h-[20px] md:w-[36px] md:h-[18px]`} fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18.8017 3.62903C18.3587 3.19147 17.6413 3.19147 17.1983 3.62903L3.34188 17.3162C2.41882 18.2279 0.923053 18.2279 -7.56916e-07 17.3162L16.3291 1.18672L17.1983 0.328169C17.6413 -0.109389 18.3587 -0.109389 18.8017 0.328169L19.6709 1.18672L36 17.3153C35.0769 18.2271 33.5812 18.2271 32.6581 17.3153L18.8017 3.62903Z" />
				</svg>
			</button>
		</VisibleTransition>, document.getElementById("root")!)}
	</div>;
};

export default PortfolioDetailPage;