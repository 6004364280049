interface LineTextProps {
	style?: string;
	text: string;
}

const LineText = ({ text, style }: LineTextProps) => {
	return <div
		className={`flex basis-full items-center w-full text-m-line md:text-w-line text-white font-m-line md:font-w-line tracking-m-line md:tracking-w-line
							 md:before:content-[' '] md:before:mr-w-line md:before:border-b md:before:border-[rgba(223,6,24,0.95)] md:before:grow
							 after:content-[' '] after:ml-m-line md:after:ml-w-line after:grow after:border-b after:border-[rgba(223,6,24,0.95)]
							 ${style}`}
	>
		{ text }
	</div>
};

export default LineText;
