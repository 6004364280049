import { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import LineText from "../../components/text/lineText";
import CompositionContainer from "../../components/container/compositionContainer";
import VisibleTransition from "@/components/visibleTransition";
import MobilitySection from "@/components/team/mobilitySection";
import DegitalSection from "@/components/team/degitalSection";
import UxSection from "@/components/team/uxSection";
import scrollbarController, { ScrollStatus } from "@/scrollbarController";
import { useLocation } from "react-router-dom";

const TeamPage = () => {
  const { hash } = useLocation();

  const topRef = useRef<HTMLDivElement>(null);
  const mobilityRef = useRef<HTMLDivElement>(null);
  const degitalRef = useRef<HTMLDivElement>(null);
  const uxRef = useRef<HTMLDivElement>(null);
  const [visibleNavigator, setVisibleNavigator] = useState(false);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const updateScrollStatus = (status: ScrollStatus) => {
      if (status.offset.y < mobilityRef.current!.offsetTop) setPosition(0);
      else if (status.offset.y < degitalRef.current!.offsetTop) setPosition(1);
      else if (status.offset.y < uxRef.current!.offsetTop) setPosition(2);
      else setPosition(3);

      setVisibleNavigator(topRef.current!.offsetTop <= status.offset.y);
    };
    scrollbarController.addListener(updateScrollStatus);
    return () => {
      scrollbarController.removeListener(updateScrollStatus);
    };
  }, []);

  useEffect(() => {
    window.setTimeout(() => { // rendering deley
      switch (hash) {
        case "#style":
          if (!mobilityRef.current) break;
          scrollbarController.scrollIntoView(mobilityRef.current!);
          setPosition(0);
          break;
        case "#cas":
          if (!degitalRef.current) break;
          scrollbarController.scrollIntoView(degitalRef.current!);
          setPosition(1);
          break;
        case "#ux":
          if (!uxRef.current) break;
          scrollbarController.scrollIntoView(uxRef.current!);
          setPosition(2);
          break;
      }
    }, 60);
  }, [hash]);

  return (
    <div ref={topRef} className="relative py-m-y md:py-w-y px-m-x md:px-w-x max-w-wide w-full m-auto">
      {/* Team 상단 문구 */}
      <VisibleTransition className="mb-m-title md:mb-w-title md:text-center">
        <h4 className="mb-m-top-title md:mb-w-top-title leading-m-title md:leading-w-title text-m-title md:text-w-title font-m-title md:font-w-title text-white">MEET OUR TEAM</h4>
        {/** PC **/}
        <p className="hidden md:block leading-w-caption text-w-caption font-w-caption">
          <FormattedMessage id="team.caption1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
        </p>
        <p className="hidden md:block leading-w-caption text-w-caption font-w-caption">
          <FormattedMessage id="team.caption2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
        </p>

        {/** Mobile **/}
        <p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
          <FormattedMessage id="team.m.caption1" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
        </p>
        <p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
          <FormattedMessage id="team.m.caption2" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
        </p>
        <p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
          <FormattedMessage id="team.m.caption3" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
        </p>
        <p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
          <FormattedMessage id="team.m.caption4" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
        </p>
      </VisibleTransition>
      {/* Composition */}
      <LineText text="TEAM COMPOSITION" />
      <ul className="pt-[30px] pb-[40px] md:pt-[60px] md:pb-[80px] flex flex-row flex-wrap justify-around md:justify-between gap-[50px]">
        <CompositionContainer type="mobility" image="/images/team/composition_image1.png" imageAlt="composition1" useHover={false} clickEvent={() => scrollbarController.scrollIntoView(mobilityRef.current!)} />
        <CompositionContainer type="degital" image="/images/team/composition_image2.png" imageAlt="composition2" useHover={false} clickEvent={() => scrollbarController.scrollIntoView(degitalRef.current!)} />
        <CompositionContainer type="ux" image="/images/team/composition_image3.png" imageAlt="composition3" useHover={false} clickEvent={() => scrollbarController.scrollIntoView(uxRef.current!)} />
        <CompositionContainer type="media" image="/images/team/composition_image4.png" imageAlt="composition4" useHover={false} />
      </ul>
      {/* 모빌리티 */}
      <MobilitySection ref={mobilityRef} />
      <DegitalSection ref={degitalRef} />
      <UxSection ref={uxRef} />

      {createPortal(<VisibleTransition visible={visibleNavigator} position="-50%" className="fixed z-[999] bottom-0 md:top-[50%] right-[15px] w-[40px] h-[40px] md:w-fit md:h-fit flex flex-col items-center justify-center gap-[58px] md:py-[30px] md:px-[6px] bg-black border border-primary rounded-md">
        <button className="group" onClick={() => scrollbarController.scrollIntoView(topRef.current!)}>
          <svg width="36" height="18" viewBox="0 0 36 18" className={`${position === 0 ? 'fill-primary' : 'fill-white'} group-hover:fill-primary transition-all w-[20px] h-[20px] md:w-[36px] md:h-[18px]`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.8017 3.62903C18.3587 3.19147 17.6413 3.19147 17.1983 3.62903L3.34188 17.3162C2.41882 18.2279 0.923053 18.2279 -7.56916e-07 17.3162L16.3291 1.18672L17.1983 0.328169C17.6413 -0.109389 18.3587 -0.109389 18.8017 0.328169L19.6709 1.18672L36 17.3153C35.0769 18.2271 33.5812 18.2271 32.6581 17.3153L18.8017 3.62903Z" />
          </svg>
        </button>
        <button className={`text-[15px] ${position === 1 ? 'text-primary' : 'text-white'} hover:text-primary transition-all hidden md:block`} onClick={() => scrollbarController.scrollIntoView(mobilityRef.current!)}><FormattedMessage id="team.floating.body1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></button>
        <button className={`text-[15px] ${position === 2 ? 'text-primary' : 'text-white'} hover:text-primary transition-all hidden md:block`} onClick={() => scrollbarController.scrollIntoView(degitalRef.current!)}><FormattedMessage id="team.floating.body2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></button>
        <button className={`text-[15px] ${position === 3 ? 'text-primary' : 'text-white'} hover:text-primary transition-all hidden md:block`} onClick={() => scrollbarController.scrollIntoView(uxRef.current!)}><FormattedMessage id="team.floating.body3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></button>
      </VisibleTransition>, document.getElementById("root")!)}
    </div>
  )
};

export default TeamPage;