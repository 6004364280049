import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import AboutPage from './pages/about';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './App.css';
import PortfolioPage from './pages/portfolio';
import ContactPage from './pages/contact';
import PortfolioDetailPage from './pages/portfolio/detail';
import TeamPage from "@/pages/team";
import Header from './components/layout/header';
import Footer from './components/layout/footer';
import { useLocation } from 'react-router-dom';

import SmoothScrollbar from './components/smoothScrollbar';
import FixedScrollStatus from './components/fixedScrollStatus';


function App() {
  const location = useLocation();
  return (
    <SmoothScrollbar>
      <FixedScrollStatus />
      <Header />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/team' element={<TeamPage />} />
        <Route path='/portfolio' element={<PortfolioPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/portfolio/:id' element={<PortfolioDetailPage />} />
      </Routes>
      {location.pathname !== '/' && <Footer />}
    </SmoothScrollbar>
  );
}

export default App;
