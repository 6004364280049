import { FormattedMessage } from "react-intl";
import VisibleTransition from "@/components/visibleTransition";

interface TeamMainImageProps {
  type: string;
  image: string;
  isUx?: boolean
}

const TeamMainImage = ({ type, image, isUx }: TeamMainImageProps) => {
  return (
    <div>
      <hr className="text-primary opacity-95" />
      <div
        className="relative z-[1] my-[10px] w-full h-[271px] md:h-[542px] bg-center bg-cover bg-no-repeat"
        // className="relative z-[1] my-[10px] w-full h-[271px] md:h-[542px] shadow-[inset_0px_0px_40px_40px] shadow-black bg-center bg-cover bg-no-repeat
        //               after:content-[''] after:absolute after:bg-black after:z-[-1] after:inset-[0] after:opacity-50"
        style={{ backgroundImage: `linear-gradient(90deg, #000, transparent 30% 70%, #000), url(${image})` }}>
        <VisibleTransition className="px-m-x flex justify-between items-end md:items-center h-full">
          <div className="flex flex-col">
            <p className="text-[16px] font-[400] leading-normal hidden md:block"><FormattedMessage id={`team.${type}.main.subtitle`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
            <div className="mb-[10px] text-[9px] font-medium leading-[20px] md:hidden">
              <p><FormattedMessage id={`team.${type}.main.subtitle_m1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
              <p><FormattedMessage id={`team.${type}.main.subtitle_m2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
              <p><FormattedMessage id={`team.${type}.main.subtitle_m3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
            </div>
            <div className="flex items-baseline gap-[10px] font-medium">
              <p className="text-[25px] md:text-[58px]"><FormattedMessage id={`team.${type}.main.title`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
              <p className="text-[12px] md:text-[22px]"><FormattedMessage id={`team.${type}.main.title_1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} defaultMessage=" " /></p>
              <p className="text-[12px] md:text-[22px]"><FormattedMessage id={`team.${type}.main.title_2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} defaultMessage=" " /></p>
            </div>
          </div>
          <div className="hidden md:block text-[18px] font-[400]">
            {isUx ?
							<>
								<p><FormattedMessage id={`team.${type}.main.display1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
								<p><FormattedMessage id={`team.${type}.main.display2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
							</> :
              <p><FormattedMessage id={`team.${type}.main.display`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
            }
          </div>
        </VisibleTransition>
        <span className="absolute bottom-[6px] md:bottom-[15px] right-[20px] text-[10px] md:text-[13px] text-gray100 font-[400]">
          <FormattedMessage id={`team.${type}.main.description`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} />
        </span>
      </div>
      <hr className="text-primary opacity-95" />
    </div>
  )
};

export default TeamMainImage;