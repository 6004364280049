import { WorkAutoData } from "@/defines/work";
import { HomeSlideData } from "@/defines/home";
import { AboutHistoryData } from "@/defines/about";

export const getHomeSlideData = async (): Promise<HomeSlideData[]> => {
    const res = await fetch(`${process.env.PUBLIC_URL}/data/home_slide.json`);
    return await res.json();
};

export const getAboutHistoryData = async (): Promise<AboutHistoryData[]> => {
  const res = await fetch(`${process.env.PUBLIC_URL}/data/about_history.json`);
  return await res.json();
};

export const getWorkAutos = async (): Promise<WorkAutoData[]> => {
    const resp = await fetch(`${process.env.PUBLIC_URL}/data/work_auto.json`);
    return await resp.json();
};

export const getWorkAutoDetail = async (id: string): Promise<WorkAutoData | null> => {
    const resp = await fetch(`${process.env.PUBLIC_URL}/data/work_auto.json`);
    const list = await resp.json() as WorkAutoData[];

    const index = list.findIndex((item) => item.id === id);
    if (index === -1) return null;
    return list[index];
};