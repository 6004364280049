import { useEffect, useRef } from "react";

import { Link, NavLink, useLocation } from "react-router-dom";
import LangSelector from "../langSelector";
import SideMenu from "./sideMenu";
import VisibleTransition from "../visibleTransition";

const Header = () => {
  const navbarsRef = useRef<HTMLLIElement[]>([]);
  const barRef = useRef<HTMLSpanElement | null>(null);
  const location = useLocation();

  useEffect(() => {
    const onResize = () => {
      const index = HeaderNavbars.findIndex(([name, pathname]) => pathname === location.pathname);
      if (index === -1) return;

      const navbarLI = navbarsRef.current[index];
      if (!navbarLI) return;

      const barEl = barRef.current;
      const parentEl = barEl?.parentElement;
      if (!barEl || !parentEl) return;

      const prect = parentEl.getBoundingClientRect();
      const rect = navbarLI.getBoundingClientRect();
      barEl.style.width = `${rect.width}px`;
      barEl.style.transform = `translate(calc(${rect.x}px - ${prect.x}px))`;
    };

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [location]);

  return (
    <VisibleTransition className="relative min-w-[280px] w-full h-[44px] md:h-[100px] border-b border-white border-opacity-25 bg-black z-10">
      <nav className="flex md:grid md:grid-cols-header gap-[12px] md:gap-0 pl-[16px] pr-[16px] md:pr-[10px] md:pl-[10px] max-w-wide items-center w-full h-full m-auto">
        <Link to="/" className="flex-auto cursor-pointer justify-start">
          <img src={`${process.env.PUBLIC_URL}/V2V_Logo_White.svg`} className="h-[19pt] md:h-auto" />
        </Link>
        <ul className="relative hidden md:grid md:grid-cols-menu max-w-[947px] w-full m-auto justify-center text-white h-full items-center">
          {HeaderNavbars.map(([name, to], index) => {
            return <li
              ref={(element) => {
                if (!element) return;
                navbarsRef.current[index] = element;
              }}
              key={`header-navigator-${name}`}
              className="flex-1 h-full text-center group"
              style={{
                gridColumn: 2 * index + 1
              }}
            >
              <NavLink to={to} className="block h-full">
                {({ isActive }) => (<div className={`flex items-center text-center h-full`}>
                  <span className={`flex-auto text-center text-[14px] leading-normal tracking-w-default group-hover:text-primary ${isActive ? 'text-primary font-bold' : 'font-medium'} `}>
                    {name}
                  </span>
                </div>)}
              </NavLink>
            </li>
          })}
          <span ref={barRef} className="absolute border-t-[3px] w-full left-0 rounded-[5px] bottom-[-1px] transition-all border-primary"></span>
        </ul>
        <LangSelector className={" justify-end "} />
        <SideMenu />
      </nav>
    </VisibleTransition>
  );
};

export default Header;
export const HeaderNavbars = [["HOME", "/"], ["ABOUT", "/about"], ["TEAM", "/team"], ["PORTFOLIO", "/portfolio"], ["CONTACT", "/contact"]];
