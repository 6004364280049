import { FormattedMessage } from "react-intl";
import CornerContainer from "../../components/container/cornerContainer";
interface DegitalProcessProps {
  id: string;
  index: string;
  processNumber: string;
}

const DegitalProcess = ({ id, index, processNumber }: DegitalProcessProps) => {
  return (
    <CornerContainer style="w-[70%] md:w-[274px] h-full">
      <div className="pt-[30px] text-center">
        <div className="mb-[10px] md:mb-[24px]">
          <p className="mb-[-4px] md:mb-[-10px] text-[10px] font-light leading-normal md:leading-[22px]">
            <FormattedMessage id={`team.degital.main.work.triangle1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
          </p>
          <p className="text-[24px] md:text-[35px] font-medium">{ processNumber }</p>
        </div>
        <div>
          <p className="text-[10px] font-light leading-normal md:leading-[22px]">
            <FormattedMessage id={ id } values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
          </p>
          <p className="text-[16px] md:text-[20px] font-medium">
            <FormattedMessage id={`${ id }.body`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
          </p>
        </div>
        <div className="mt-[30px] flex flex-col items-center text-[8px] md:text-[15px] font-light leading-[13px] md:leading-[22px]">
          <span><FormattedMessage id={`${id}.body_1`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></span>
          <div className={`my-[8px] w-[10px] h-[10px] border-r border-t rotate-45 ${localStorage.getItem('lang') === 'en' ? 'hidden' : ''}`} />
          <span><FormattedMessage id={`${id}.body_2`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></span>
          <span><FormattedMessage id={`${id}.body_5`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }} defaultMessage=" "/></span>
          <hr className="my-[15px] md:my-[20px] w-[39px] text-primary" />
          <span className="font-medium"><FormattedMessage id={`${id}.body_3`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></span>
          <span className="font-medium"><FormattedMessage id={`${id}.body_4`} values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/></span>
          <img className="mt-[20px] w-[140px] md:w-[214px] h-[97px] md:h-[150px]" src={`/images/team/degital_work${index}.png`} alt="Degital_Work_Image" />
        </div>
      </div>
    </CornerContainer>
  )
};

export default DegitalProcess;