import { useEffect, useMemo, useState, useRef, forwardRef, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";

// import portFolioData from "@/data/portfolio.json";
import { Link, useLocation } from "react-router-dom";
import VisibleTransition from "@/components/visibleTransition";
import scrollbarController, { ScrollStatus } from "@/scrollbarController";
import WorkCartModal from "@/components/workCartModal";
import { WorkAutoData } from "@/defines/work";
import { getWorkAutos } from "@/api/work";
import LineText from "@/components/text/lineText";
import { createPortal } from "react-dom";

enum PortfolioTabItems {
	AUTO = 0,
	CART = 1,
}

const PortfolioPage = () => { // 포트폴리오 페이지
	const cartListRef = useRef<(HTMLLIElement | null)[]>([]);
	const topRef = useRef<HTMLDivElement>(null);

	const { hash } = useLocation();
	const [modal, setModal] = useState(false);

	const [tabItem, setTabItem] = useState(hash === "#cart" ? PortfolioTabItems.CART : PortfolioTabItems.AUTO);
	const [visibleItems, setVisibleItems] = useState<string[]>([]);
	const [workDatas, setWorkDatas] = useState<WorkAutoData[]>([]);

	const isAuto = tabItem === PortfolioTabItems.AUTO;

	const changeTabItem = (e: React.MouseEvent<HTMLLIElement>) => {
		let target: HTMLElement | null = (e.target as HTMLLIElement);
		while (target && target.nodeName !== "LI") {
			target = target.parentElement;
		}
		if (!target || target.nodeName !== "LI") return;
		setTabItem((target as HTMLLIElement).value as PortfolioTabItems);
	};

	useLayoutEffect(() => {
		setTabItem(hash === "#cart" ? PortfolioTabItems.CART : PortfolioTabItems.AUTO);
		scrollbarController.scrollIntoView(topRef.current!);
	}, [hash]);

	useEffect(() => {
		getWorkAutos().then(setWorkDatas);
	}, []);

	return <div className="py-m-y md:py-w-y" ref={topRef}>
		<VisibleTransition /// 상단
			className={`${tabItem === PortfolioTabItems.CART ? 'hidden' : 'block'} md:mb-w-title max-w-wide w-full m-auto md:px-w-x px-w-x`}
		>
			<h4 className="mb-m-top-title md:mb-w-top-title leading-m-title md:leading-w-title text-m-title md:text-w-title font-m-title md:font-w-title text-white md:text-center">PORTFOLIO</h4>
			{/** MO */}
			<p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
				<FormattedMessage id={isAuto ? "m.work.auto.body1" : "m.work.cart.body1"} values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
				<FormattedMessage id={isAuto ? "m.work.auto.body2" : "m.work.cart.body2"} values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
				<FormattedMessage id={isAuto ? "m.work.auto.body3" : "m.work.cart.body3"} values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
				<FormattedMessage id={isAuto ? "m.work.auto.body4" : "m.work.cart.body4"} values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			{(!isAuto && localStorage.getItem('lang') === 'en') && <p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
				<FormattedMessage id="m.work.cart.body5" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>}

			{/** PC */}
			<p className="hidden md:block leading-w-caption text-w-caption font-w-caption text-center">
				<FormattedMessage id={isAuto ? "portfolio.auto.body1" : "portfolio.cart.body1"} values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<p className="hidden md:block leading-w-caption text-w-caption font-w-caption text-center">
				<FormattedMessage id={isAuto ? "portfolio.auto.body2" : "portfolio.cart.body2"} values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
		</VisibleTransition>

		{/** Cart 상단 **/}
		<VisibleTransition className={`${tabItem === PortfolioTabItems.CART ? 'block' : 'hidden'} md:mb-w-title max-w-wide w-full m-auto md:px-w-x px-w-x`}>
			<p className="text-[14px] leading-[15px] mb-[15px] md:mb-[20px] md:text-[24px] md:leading-[15px] font-medium md:text-center">
				<FormattedMessage id="work.cart.company" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<h3 className="text-[24px] md:text-[40px] md:mt-[8px] font-medium md:text-center">
				<FormattedMessage id="work.cart.title" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</h3>
			<div className="text-[9px] leading-[15px] md:text-[16px] md:leading-[28px] mt-[15px] md:mt-[30px] font-medium md:font-light md:text-center">
				<p className="hidden md:block"><FormattedMessage id="work.cart.w.body1" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} /></p>
				<p className="hidden md:block"><FormattedMessage id="work.cart.w.body2" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} /></p>
				<p className="hidden md:block"><FormattedMessage id="work.cart.w.body3" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} /></p>

				<p className="block md:hidden"><FormattedMessage id="work.cart.m.body1" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} /></p>
				<p className="block md:hidden"><FormattedMessage id="work.cart.m.body2" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} /></p>
				<p className="block md:hidden"><FormattedMessage id="work.cart.m.body3" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} /></p>
				<p className="block md:hidden"><FormattedMessage id="work.cart.m.body4" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} /></p>
				<p className="block md:hidden"><FormattedMessage id="work.cart.m.body5" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} defaultMessage=" " /></p>
			</div>
			<p className="text-[9px] leading-[15px] md:text-[15px] md:leading-[19px] mt-[8px] md:mt-[30px] font-medium md:font-light md:text-center">
				<FormattedMessage id="work.cart.description1" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
			<p className="text-[9px] leading-[15px] md:text-[15px] md:leading-[19px] mt-[4px] md:mt-[10px] font-medium md:font-light md:text-center">
				<FormattedMessage id="work.cart.description2" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
			</p>
		</VisibleTransition>

		<LineText text="PROJECT" style={`${tabItem === PortfolioTabItems.CART ? 'hidden' : 'block'} pt-[28px] md:pt-0 px-w-x m-auto md:max-w-wide`} />

		{/**
		<div // PC
			className="hidden md:flex flex-row items-center max-w-wide w-full m-auto px-w-x pt-[7px]">
			<hr className="flex-auto border-primary border-opacity-95" />
			<ul className="flex flex-row gap-2 flex-[0_1_500px] px-w-line">
				<li className={`relative text-left flex-auto text-[12px] md:text-[14px] leading-[30px] md:leading-[15px] tracking-m-default md:tracking-w-default font-bold cursor-pointer ${tabItem === PortfolioTabItems.AUTO ? "text-primary" : ""}`} value={PortfolioTabItems.AUTO} onClick={changeTabItem}>AUTO</li>
				<li className={`relative text-right flex-auto text-[12px] md:text-[14px] leading-[30px] md:leading-[15px] tracking-m-default md:tracking-w-default font-bold cursor-pointer ${tabItem === PortfolioTabItems.CART ? "text-primary" : ""}`} value={PortfolioTabItems.CART} onClick={changeTabItem}>
					<span className={`relative group  ${tabItem === PortfolioTabItems.CART ? "text-primary" : ""}`}>
						CART
						<VisibleTransition
							visible={!isAuto}
							className="absolute rounded-[8px] w-[250px] ml-[-125px] left-[50%] top-[100%] p-[24px] mt-[calc(25px+13px)] bg-[#292929]">
							<img className="absolute top-[-13px] left-[50%] ml-[-7px]" src={`${process.env.PUBLIC_URL}/images/portfolio/cart_arrow.svg`} alt='' />
							<ul className="flex items-center rounded-[8px] gap-[40px]">
								<li value={PortfolioTabItems.CART} className="flex-[1_1_50%] text-center text-white hover:text-primary font-bold text-[14px] leading-[15px] tracking-w-default">
									<a onClick={(e) => {
										e.preventDefault();
										const w = cartListRef.current[0];
										if (w) {
											scrollbarController.scrollIntoView(w);
										}
									}}>PROCESS</a>
								</li>
								<li value={PortfolioTabItems.CART} className="flex-[1_1_50%] text-center text-white hover:text-primary font-bold text-[14px] leading-[15px] tracking-w-default">
									<a onClick={(e) => {
										e.preventDefault();
										const w = cartListRef.current[14];
										if (w) {
											scrollbarController.scrollIntoView(w);
										}
									}}>RENDER</a>
								</li>
							</ul>
						</VisibleTransition>
					</span>
				</li>
			</ul>
			<hr className="flex-auto border-primary border-opacity-95" />
		</div>

		<div // MO
			className={`flex md:hidden ${(!isAuto && localStorage.getItem('lang') === 'en') ? 'mt-[8px]' : 'mt-[28px]'} flex-row items-center max-w-wide w-full m-auto px-w-x z-10 relative`}>
			<ul className="flex flex-row flex-auto items-center gap-[16px]">
				<li className={`text-center flex-0 text-[12px] leading-[30px] tracking-[3px] font-semibold cursor-pointer ${tabItem === PortfolioTabItems.AUTO ? "text-primary" : ""}`} value={PortfolioTabItems.AUTO} onClick={changeTabItem}>AUTO</li>
				<hr className="flex-auto border-primary border-opacity-05" />
				<li className={`text-center flex-0 text-[12px] leading-[30px] tracking-[3px] font-semibold cursor-pointer ${tabItem === PortfolioTabItems.CART ? "text-primary" : ""}`} value={PortfolioTabItems.CART} onClick={changeTabItem}>CART</li>
			</ul>
		</div>**/}

		<div className={`${tabItem === PortfolioTabItems.CART ? 'block' : 'hidden'} text-center max-w-wide w-full m-auto mt-[32px] md:mt-[calc(25px+13px)]`}>
			<button onClick={() => setModal(true)} className="text-[12px] hover:text-primary font-semibold tracking-[3px] md:text-[14px] leading-[30px] md:leading-[15px] md:font-bold md:tracking-[2.24px] md:py-[24px] px-m-x md:px-w-x underline">
				SKETCH
			</button>
		</div>

		{
			isAuto ? <VisibleTransition /// 리스트영역
				tag="ul"
				visible={isAuto}
				className="max-w-wide w-full m-auto px-w-x flex flex-col gap-[12px] mt-[24px] md:grid md:mt-[60px] md:gap-[20px] md:grid-cols-work"
			>
				{workDatas.map((props) => {
					const { id, imageUrl, date } = props;
					const client = props[`client_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`]
					const title = props[`title_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`]
					const description1 = props[`description1_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`]
					const description2 = props[`description2_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`]
					return <VisibleTransition
						tag="li"
						key={`work-${tabItem}-${id}`}
						visible={visibleItems.includes(id)}
						className={`${isAuto ? `border-[1px] border-secondary` : ""} group relative truncate max-h-[199px] md:max-h-[356px]`}
					>
						<Link to={`${id === 'cart' ? `/portfolio#cart`: `/portfolio/${id}`}`}>
							<img
								src={`${process.env.PUBLIC_URL}${imageUrl}`}
								className="scale-100 group-hover:scale-110 transition-all duration-500"
								onLoad={() => setVisibleItems(prev => prev.concat([id]))}
								alt="포트폴리오_아이템"
							/>
							<div className="flex flex-row items-center absolute top-0 left-0 w-full h-full opacity-0 group-hover:opacity-100 bg-opacity-0 group-hover:bg-black group-hover:bg-opacity-50 transition-all duration-500 cursor-pointer">
								<div className="w-full">
									<p className="text-center text-[12px] leading-[15px] md:text-[18px] md:leading-[15px] md:min-h-[39px] break-words font-medium">{client}</p>
									<h3 className="text-center text-[16px] leading-[15px] mt-[15px] md:mt-0 md:text-[30px] md:leading-[15px] md:min-h-[39px] break-words font-medium">{title}</h3>
									<p className="text-center text-[10px] leading-[18px] md:text-[16px] md:leading-[28px] mt-[12px] md:mt-0 font-light">{description2}</p>
									<p className="text-center text-[10px] leading-[18px] md:text-[16px] md:leading-[28px] font-light">{date}</p>
								</div>
							</div>
						</Link>
					</VisibleTransition>;
				})}
			</VisibleTransition> : <div>
				<ul className="hidden md:block mt-[184px]">
					{Array(22).fill(0)
						.map((_, index) => `PC_${window.localStorage.getItem('lang') === 'en' ? 'ENG' : 'KOR'}_${`0${index + 1}`.slice(-2)}_1.png`)
						.map((url, index) => <li value={index + 1} key={`pc_cart_${url}`} ref={(element) => cartListRef.current.push(element)}>
							<img
								src={`${process.env.PUBLIC_URL}/images/cart/pc_${window.localStorage.getItem('lang') ?? 'ko'}/${url}`}
								alt=''
							/>
						</li>)}
				</ul>
				<div className="block md:hidden mt-[40px]">
					<img
						src={`${process.env.PUBLIC_URL}/images/cart/mo_${window.localStorage.getItem('lang') ?? 'ko'}/Mobile_01_1.png`}
						alt=''
					/>
				</div>
			</div>
		}
		<WorkCartModal display={modal} onClose={() => setModal(false)} />

		{createPortal(<VisibleTransition position="-50%" className={`${tabItem === PortfolioTabItems.CART ? 'flex' : 'hidden'} fixed z-[999] bottom-0 md:top-[50%] right-[15px] w-[40px] h-[40px] md:w-[64px] md:h-[64px] items-center justify-center gap-[58px] md:py-[30px] md:px-[6px] bg-black border border-primary rounded-md`}>
			<button className="group" onClick={() => scrollbarController.scrollIntoView(topRef.current!)}>
				<svg width="36" height="18" viewBox="0 0 36 18" className={`fill-white group-hover:fill-primary transition-all w-[20px] h-[20px] md:w-[36px] md:h-[18px]`} fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18.8017 3.62903C18.3587 3.19147 17.6413 3.19147 17.1983 3.62903L3.34188 17.3162C2.41882 18.2279 0.923053 18.2279 -7.56916e-07 17.3162L16.3291 1.18672L17.1983 0.328169C17.6413 -0.109389 18.3587 -0.109389 18.8017 0.328169L19.6709 1.18672L36 17.3153C35.0769 18.2271 33.5812 18.2271 32.6581 17.3153L18.8017 3.62903Z" />
				</svg>
			</button>
		</VisibleTransition>, document.getElementById("root")!)}
	</div>;
};

export default PortfolioPage;