import { createPortal } from "react-dom";
import VisibleTransition from "../visibleTransition";

interface FixedNavigatorProps {
    visible?: boolean;

    onPrevPage(): void;
    onNextPage(): void;
}

const FixedNavigator = ({
    visible,

    onNextPage,
    onPrevPage,
}: FixedNavigatorProps) => {
    return createPortal(<>
        <VisibleTransition
            tag="button"
            visible={visible}
            onClick={onPrevPage}
            position="-51px"
            className="hidden md:block fixed left-0 top-[50%] p-[30px] translate-x-[0px] group z-10"
        >
            <svg width="17" height="42" viewBox="0 0 17 42" className="fill-white group-hover:fill-primary transition-all" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 21.433H2.51L16.15 38.774C16.85 39.6646 16.85 41.115 16.15 42.0056L0 21.433Z" />
                <path d="M0 21.433H2.51L16.15 4.07922C16.85 3.18863 16.85 1.73825 16.15 0.847656L0 21.433Z" />
            </svg>
        </VisibleTransition>
        <VisibleTransition
            tag="button"
            visible={visible}
            onClick={onNextPage}
            position="-51px"
            className="hidden md:block fixed right-0 top-[50%] p-[30px] translate-x-[0px] group z-10"
        >
            <svg width="17" height="42" viewBox="0 0 17 42" className="fill-white group-hover:fill-primary transition-all" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.7199 21.433H14.2099L0.569922 38.774C-0.130078 39.6646 -0.130078 41.115 0.569922 42.0056L16.7199 21.4203V21.433Z" />
                <path d="M16.7199 21.433H14.2099L0.569922 4.07922C-0.130078 3.18863 -0.130078 1.73825 0.569922 0.847656L16.7199 21.433Z" />
            </svg>
        </VisibleTransition>
    </>, document.getElementById('root')!);
};
export default FixedNavigator;