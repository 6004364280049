import { createElement, useEffect, useRef, useState } from "react";

interface VisibleTransitionProps {
	tag?: string;
	visible?: boolean;
	/** 시작하는 y 위치 */
	position?: string;
	className?: String;
	onClick?: React.MouseEventHandler<HTMLElement>;
}

const VisibleTransition = ({
	tag,
	visible,
	position,
	className,
	children,
	onClick,
}: React.PropsWithChildren<VisibleTransitionProps>) => {
	const ref = useRef<HTMLElement | null>(null);
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach(({ target, isIntersecting }) => {
				if (target === ref.current && isIntersecting) {
					setIntersecting(isIntersecting);
				}
			});
		}, {
			threshold: 0.25
		});

		if (ref.current) observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, []);

	return createElement(tag!, {
		ref,
		className: `${(visible && isIntersecting) ? "opacity-100 visible" : "opacity-0 invisible"} transition-all duration-500 ${className || ""}`,
		onClick,
		style: {
			transform: visible && isIntersecting ? `translateY(${position})` : `translateY(calc(${position} + 15pt))`,
		}
	}, children);
};
export default VisibleTransition;
VisibleTransition.defaultProps = {
	tag: "div",
	visible: true,
	className: "",
	position: "0px",
};