import { createPortal } from "react-dom";
import VisibleTransition from "../visibleTransition";
import { useEffect, useRef } from "react";
import Scrollbar from 'smooth-scrollbar';

interface WorkCartModalProps {
    display: boolean;
    onClose(): void;
}

const WorkCartModal = ({
    display,
    onClose,
}: WorkCartModalProps) => {
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!contentRef.current) return;
        if (display) {
            const scrollbar = Scrollbar.init(contentRef.current!, {
                damping: 0.1,
                thumbMinSize: 8,
                renderByPixels: true,
                alwaysShowTracks: false,
                continuousScrolling: true,
            });
            return () => scrollbar.destroy();
        }
    }, [display]);

    return createPortal(<div className={`fixed z-50 top-0 left-0 w-[100vw] h-[100vh] py-m-x px-m-x  md:py-w-x md:px-w-x ${display ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-all`}
        onClick={onClose}
    >
        <VisibleTransition visible={display} className="relative grid gap-[6px] md:gap-[12px] grid-rows-m-modal md:grid-rows-w-modal border-[#FFFFFF] border-opacity-40 border-[1px] border-solid rounded-[16px] max-w-[1420px] m-auto w-full bg-[#212121] z-10"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <header className="p-[16px] md:p-[24px] text-right">
                <button className="p-0 m-0 border-0 cursor-pointer group" onClick={onClose}>
                    <svg width="26" height="26" viewBox="0 0 26 26" className={` group-hover:stroke-primary stroke-white transition-all w-[16px] h-[16px] md:w-[26px] md:h-[26px]`} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Group 165">
                            <path id="Vector 9" d="M1 1L25 25" stroke-width="2" stroke-linecap="round" />
                            <path id="Vector 10" d="M25 1L0.999998 25" stroke-width="2" stroke-linecap="round" />
                        </g>
                    </svg>
                </button>
            </header>
            <div ref={contentRef} className="mx-[8px] md:mx-[16px]">
                <div className="px-[12px] md:px-[24px] pb-[22px] md:pb-[34px]">
                    <img src={`${process.env.PUBLIC_URL}/images/w_work_cart.png`} />
                    {/*<img src={`${process.env.PUBLIC_URL}/images/w_work_cart_1.png`} />*/}
                    {/*<img src={`${process.env.PUBLIC_URL}/images/w_work_cart_2.png`} />*/}
                    {/*<img src={`${process.env.PUBLIC_URL}/images/w_work_cart_3.png`} />*/}
                </div>
            </div>
        </VisibleTransition>
        <div className="absolute top-0 left-0 z-0 w-full h-full bg-[#000] bg-opacity-40" />
    </div>, document.getElementById("root")!);
};
export default WorkCartModal;