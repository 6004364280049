import scrollbarController from '@/scrollbarController';
import React, { useRef, useEffect } from 'react';
import Scrollbar from 'smooth-scrollbar';

const SmoothScrollbar = ({ children }: React.PropsWithChildren) => {
    const scrollbarRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!scrollbarRef.current) return;
        scrollbarController.init(Scrollbar.init(scrollbarRef.current, {
            damping: 0.1,
            thumbMinSize: 20,
            renderByPixels: true,
            alwaysShowTracks: false,
            continuousScrolling: true,
        }));

        return () => scrollbarController.destory();
    }, []);

    return (
        <div ref={scrollbarRef}
            style={{
                maxWidth: '100vw',
                maxHeight: '100vh',
            }}
        >
            <div // safe-area
            >
                {children}
            </div>
        </div>
    );
};
export default SmoothScrollbar;