interface FooterProps {
	className?: string;
}

const Footer = ({ className }: FooterProps) => {
	return <footer className={`flex items-center justify-center w-full h-[50px] pl-[16px] pr-[16px] ${className}`}>
		<span className="font-medium text-gray400 text-[10px]">
			Copyright@Studiov2v Co. All Rights Reserved
		</span>
	</footer>
};
export default Footer;
Footer.defaultProps = {
	className: ''
};