import { FormattedMessage } from "react-intl";
import LineText from "../../components/text/lineText";
import CornerContainer from "../../components/container/cornerContainer";
import BorderText from "../../components/text/borderText";
import HistoryText from "../../components/text/historyText";
import VisibleTransition from "@/components/visibleTransition";
import { useEffect, useState } from "react";
import { AboutHistoryData } from "@/defines/about";
import { getAboutHistoryData } from "@/api/work";

const AboutPage = () => {
  const [aboutHistory, setAboutHistory] = useState<AboutHistoryData[]>([]);

  useEffect(() => {
    getAboutHistoryData().then(setAboutHistory);
  }, []);

  return (
    <div className="py-m-y md:py-w-y px-m-x md:px-w-x w-full md:max-w-wide h-full m-auto bg-black">
      {/* About 상단 문구 */}
      <VisibleTransition className="mb-m-title md:mb-w-title md:text-center">
        <h4 className="mb-m-top-title md:mb-w-top-title leading-m-title md:leading-w-title text-m-title md:text-w-title font-m-title md:font-w-title">ABOUT US</h4>
        {/** PC **/}
        <p className="hidden md:block leading-w-caption text-w-caption font-w-caption">
          <FormattedMessage id="about.caption1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
        </p>
        <p className="hidden md:block leading-w-caption text-w-caption font-w-caption">
          <FormattedMessage id="about.caption2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }}/>
        </p>

        {/** Mobile **/}
        <p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
          <FormattedMessage id="about.m.caption1" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
        </p>
        <p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
          <FormattedMessage id="about.m.caption2" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
        </p>
        <p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
          <FormattedMessage id="about.m.caption3" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
        </p>
        <p className="block md:hidden leading-m-caption text-m-caption font-m-caption">
          <FormattedMessage id="about.m.caption4" values={{ locale: localStorage.getItem("lang") ?? 'ko' }} />
        </p>
      </VisibleTransition>

      {/* 솔루션 */}
      <LineText text="SOLUTION PART"/>
      <ul className="my-[30px] md:my-[60px] flex gap-[20px] md:gap-[50px] justify-around">
        <CornerContainer style="w-1/2 md:w-[419px]">
          <div className="relative w-full h-full flex flex-col items-center justify-center py-[25px] md:py-[40px]">
            <BorderText style="text-[30px] md:text-[75px] font-medium leading-normal" text="MOBILITY" />
            <hr className="mt-[10px] mb-[15px] md:mt-[20px] md:mb-[30px] mx-auto w-[30px] md:w-[40px] border-primary" />
            <p className="text-[10px] md:text-[16px] leading-[15px] md:leading-[30px] font-light"><FormattedMessage id="about.mobility.label1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
            <p className="text-[10px] md:text-[16px] leading-[15px] md:leading-[30px] font-light"><FormattedMessage id="about.mobility.label2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
            <p className="text-[10px] md:text-[16px] leading-[15px] md:leading-[30px] font-light"><FormattedMessage id="about.mobility.label3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
          </div>
        </CornerContainer>

        <CornerContainer style="w-1/2 md:w-[419px]">
          <div className="relative w-full h-full flex flex-col items-center justify-center py-[25px] md:py-[40px]">
            <BorderText style="text-[30px] md:text-[75px] font-medium leading-normal" text="UX/GUI" />
            <hr className="mt-[10px] mb-[15px] md:mt-[20px] md:mb-[30px] mx-auto w-[30px] md:w-[40px] border-primary" />
            <p className="text-[10px] md:text-[16px] leading-[15px] md:leading-[30px] font-light"><FormattedMessage id="about.ux.label1" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
            <p className="text-[10px] md:text-[16px] leading-[15px] md:leading-[30px] font-light"><FormattedMessage id="about.ux.label2" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
            <p className="text-[10px] md:text-[16px] leading-[15px] md:leading-[30px] font-light"><FormattedMessage id="about.ux.label3" values={{ locale: localStorage.getItem('lang') ?? 'ko' }} /></p>
          </div>
        </CornerContainer>
      </ul>

      {/* 히스토리 */}
      <LineText text="HISTORY"/>
      <div className="mt-[30px] mb-[45px] md:my-[60px]">
        {aboutHistory.map((props)=> {
          const { id, platform, contents } = props;
          const title = props[`title_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`];
          return <VisibleTransition
            className={`${ platform === 'mw' ? '' : platform === 'w' ? 'hidden md:block' : 'block md:hidden' } mb-[30px] md:mb-[60px] last:md:mb-[80px]`}
            key={`history_${id}`}
          >
            <h5 className="mb-[10px] md:mb-[20px] text-[12px] md:text-[22px] text-primary font-semibold uppercase">
              { title }
            </h5>
            <div className="flex flex-col gap-[5px] md:gap-[17px]">
              { contents.map((props) => {
                const { id, year } = props;
                const com = props[`com_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`];
                const text = props[`text_${(localStorage.getItem('lang') ?? 'ko') as 'ko' | 'en'}`];
                return <HistoryText key={ id } year={ year } com={ com } text={ text } />
              })}
            </div>
          </VisibleTransition>
        })}
      </div>

      {/* 파트너 */}
      <LineText text="PARTNER"/>
      <div className="mt-[120px] flex flex-col hidden md:block">
        <VisibleTransition className="grid grid-cols-3 place-items-center">
          <div className="flex items-center justify-center w-[300px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther1.svg`} alt="partner_icon1" />
          </div>
          <div className="flex items-center justify-center w-[220px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther2.svg`} alt="partner_icon2" />
          </div>
          <div className="flex items-center justify-center w-[247px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther3.svg`} alt="partner_icon3" />
          </div>
        </VisibleTransition>

        <VisibleTransition className="mt-[120px] grid grid-cols-3 place-items-center">
          <div className="flex items-center justify-center w-[251px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther4.svg`} alt="partner_icon4" />
          </div>
          <div className="flex items-center justify-center w-[269px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther5.svg`} alt="partner_icon5" />
          </div>
          <div className="flex items-center justify-center w-[240px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther6.svg`} alt="partner_icon6" />
          </div>
        </VisibleTransition>

        <VisibleTransition className="mt-[120px] grid grid-cols-3 place-items-center">
          <div className="flex items-center justify-center w-[270px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther7.svg`} alt="partner_icon4" />
          </div>
          <div className="flex items-center justify-center w-[314px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther8.svg`} alt="partner_icon5" />
          </div>
          <div className="flex items-center justify-center w-[285px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther9.svg`} alt="partner_icon6" />
          </div>
        </VisibleTransition>
      </div>

      <div className="my-[50px] flex flex-col gap-[70px] place-items-center block md:hidden">
        <VisibleTransition className="grid grid-cols-2 gap-[70px]">
          <div className="flex items-center justify-center w-[137px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther1.svg`} alt="partner_icon1_Hyundai" />
          </div>
          <div className="flex items-center justify-center w-[104px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther3.svg`} alt="partner_icon2_KN" />
          </div>
        </VisibleTransition>

        <VisibleTransition className="grid grid-cols-2 gap-[70px]">
          <div className="flex items-center justify-center w-[109px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther2.svg`} alt="partner_icon3_Hyundai_Mobis" />
          </div>
          <div className="flex items-center justify-center w-[113px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther5.svg`} alt="partner_icon4_KG_Mobility" />
          </div>
        </VisibleTransition>

        <VisibleTransition className="grid grid-cols-2 gap-[70px]">
          <div className="flex items-center justify-center w-[106px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther4.svg`} alt="partner_icon5_DAS" />
          </div>
          <div className="flex items-center justify-center w-[109px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther6.svg`} alt="partner_icon6_BOBCAT" />
          </div>
        </VisibleTransition>

        <VisibleTransition className="grid grid-cols-2 gap-[70px]">
          <div className="flex items-center justify-center w-[120px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther7.svg`} alt="partner_icon5_DAS" />
          </div>
          <div className="flex items-center justify-center w-[119px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther9.svg`} alt="partner_icon6_BOBCAT" />
          </div>
        </VisibleTransition>

        <VisibleTransition className="grid grid-cols-2 gap-[70px]">
          <div className="flex items-center justify-center w-[132px]">
            <img src={`${process.env.PUBLIC_URL}/images/about/about_parther8.svg`} alt="partner_icon5_DAS" />
          </div>
        </VisibleTransition>
      </div>
    </div>
  )
};
export default AboutPage;